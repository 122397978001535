import React from "react"
import SEO from "../components/seo"
import { FaEnvelope } from 'react-icons/fa'

import "../scss/aboutus.scss"

import Image1 from "../assets/img/youpalGroup/aboutUs/img1.png"
import Image2 from "../assets/img/youpalGroup/aboutUs/img2.png"
import Image3 from "../assets/img/youpalGroup/aboutUs/img3.png"
import Image4 from "../assets/img/youpalGroup/aboutUs/img4.png"

const AboutUsPage = () => {
  return (
    <div>
      <SEO title="About Us" keywords={[`youpal`, `youpal group`]} />
      <section className="container aboutUsPageSection">
        <h2>It is about, You, Me and Us</h2>
        <p>
          Every story is similar to each other or at least has similar
          beginnings. Companies are created and founded because there was a
          necessity and because the right people found each other with the right
          values and the right time. In these terms, you will not find anything
          radically different about the Youpal Group story, though what you will
          find is that Youpal Group managed to create not the only story but a
          history.
        </p>
        <p>
          Creating a history needs courage and a vibrant goal. It needs a truly
          strong spirit and sparkle. Karl Leahlander and Ruben Teijeiro managed
          to set the fire from the small sparkle and founded Sweden originated
          the Youpal Group company back in 2016. Shortly after, two of them were
          joined by James Baker-Duly.
        </p>
        <p>
          This trio managed to reflect their passion and dedication to the
          company they managed to give the whole company humanity and charisma.
          This is the reason why ever since the very beginning, Youpal has grown
          and transferred into a place where you can never get tired of
          innovations and developments. Youpal Group is currently operating in 8
          different countries on three continents and in almost every single
          industry and field, you could probably think of.
        </p>
        <img className="contactUsHeaderImg" src={Image1} />
      </section>

      <section className="container aboutUsPageSection">
        <div className="row">
          <div className="col">
            <img src={Image2} />
          </div>
          <div className="col">
            <h2>It is about, You, Me and Us</h2>
            <p>
              The reason why Youpal is capable of new achievements and constant
              development in a very short period of time is that the vision and
              the goals are something that unites people within the company. The
              vision and goals are to make the overall global digital market
              more comfortable and accessible for people all over the world. The
              company is oriented on creating innovative and technologically
              advanced solutions for every industry. Every new achievement opens
              the new door to new levels, and every new door is wider and
              larger.
            </p>
            <p>
              Initially, the company was founded with the IT focus, though time
              by time, it became clear that IT is everything and everything
              could be in IT. Thus, the vision of the company evolved and now
              implies full virtualization and technological advancement of every
              industry and business, regardless of the size and type.
            </p>
            <p>
              Creating the roadmap to the suited solutions for businesses is
              what Youpal’s vision has diverted into. With the Youpal roadmap,
              it doesn't really matter where you start, because we can always
              take you to the point where you want to be. I am because You are,
              and We are because you want to be with Us.
            </p>
          </div>
        </div>
      </section>

      <section className="container aboutUsPageSection">
        <div className="row">
          <div className="col">
            <h2>Without You, there is no Pal</h2>
            <p>
              Without You, there is no Youpal, as you are the most important
              component. With this, Youpal does not only contribute to the
              improvement and technological progress of your business but
              impacts your culture, because we are eager to name Youpal as a
              digital infrastructure culture enabler. The culture enabling and
              implementing is our main key to every single door we open.
            </p>
            <p>
              Here, in the Youpal Group, we can get you acquainted with
              technology-agnostic solutions, and create a culture that
              understands the purpose and goal of any digital implementation,
              installation, strategy, or change. We want to make you part of the
              foundation and infrastructure of any business domain.
            </p>
          </div>
          <div className="col">
            <img src={Image3} />
          </div>
        </div>
      </section>

      <section className="container aboutUsPageSection">
        <div className="row">
          <div className="col">
            <img src={Image4} />
          </div>
          <div className="col">
            <h2>We are being Technizmatic</h2>
            <p>
              With the ever-changing and challenging technological progress, it
              is hard to keep up with them, though, here we create our own
              rhythm of changes. We establish innovation strategies and identify
              our future as a smart and better future.
            </p>
            <p>We add charisma to technology and thus, make it outstanding!</p>
          </div>
        </div>
      </section>

      <section className="container aboutUsPageSection">
        <div className="row contactBox">
            <div className="col">
                <h2>Contact us</h2>
                <p>
                  The team of extremely trustworthy people is working every day, to
                  provide you with the necessary information and tips so we would
                  like to hear from you.
                </p>
                <div className="sendLinkContainer">
                    <div className="input-group">
                        <FaEnvelope size={25} />
                        <input type="text" className="form-control" placeholder="Your Email" aria-label="Your Email" aria-describedby="button-addon2" />
                        <div className="input-group-append">
                            <button className="btn btn-outline-secondary" type="button" id="button-addon2">Send</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </section>
    </div>
  )
}

export default AboutUsPage
